<script>
import colorsService from '@/services/colors.service'
import Color from '@/data/models/Color'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleColor',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Color,
      photo: null,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
	methods: {
    _get(type = null) {
      colorsService.getById({ id: this.id })
      .then(res => {
        this.item = res.data
      })
    },
    create() {
      colorsService.create({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('color') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'colors' })
        })
    },
    edit() {
      colorsService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('color') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    _updateHex(input) {
      const fixedInput = input.replace('#', '').toUpperCase()
      this.item.hex = fixedInput
    }
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :supports-editor="false"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    @submit="isNew ? create() : edit()"
  >
    <div
      slot="middle"
      class="row"
    >
      <BaseInput
        :value="'#' + item.hex"
        label="HEX"
        max-length="6"
        type="color"
        class="col-md-6"
        @input="_updateHex"
      />
      <BaseInput
        :value="item.hex"
        label="Hex by Typing"
        placeholder="Entere hex without #"
        max-length="6"
        type="text"
        class="col-md-6"
        @input="_updateHex"
      />
    </div>
    <div slot="sidebar-middle">
      <BaseInput
        label="Transparent"
        is-slider-checkbox
      >
        <input
          v-model="item.transparent"
          type="checkbox"
          true-value="1"
          false-value="0"
        >
      </BaseInput>
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
