export default class Color {
  hex;
  name;
  transparent;

  constructor() {
    this.hex = '';
    this.name = '';
    this.transparent = 0;
  }
}